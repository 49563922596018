// Leaderboard.jsx
//
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { UserService } from "../../UserService";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import Loader from "./../../components/Loader/Loader";
import NFTListComponent from "./NFTListComponent";
import leaderboardImage from "./leaderboardImage.jpg";


const Leaderboard = () => {
  const [leaders, setLeaders] = useState([]);
  const { name } = useSelector((store) => store.user);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(-1);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [bonusValues, setBonusValues] = useState({});

  const updatePlayersData = () => {
    UserService.getPlayers()
      .then((players) => {
        const sorted = players?.rows.sort((a, b) => b.leaderboardpts - a.leaderboardpts);
        setLeaders(sorted);
        if (players?.rows.length > 0) {
          const index = players?.rows.findIndex((player) => player.player === name);
          if (index >= 0) {
            setCurrentPlayerIndex(index);
            setCurrentPlayer(players?.rows[index]);
          }
        }
      })
      .catch((error) => {
        toast.error("Failed to get players");
      });
  };

  useEffect(() => {
    updatePlayersData();
    const interval = setInterval(updatePlayersData, 900000);
    return () => clearInterval(interval);
  }, [name]);

  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
	  <div>     
	    <img src={leaderboardImage} style={{ width: '100%', height: 'auto' }} alt="Leaderboard" />
	  </div>
	  <div>
	    <h2 className={`${styles.container_leaderboard} `}>Season 9 : 15th Apr/14th Mai 2024</h2>
	    <h1 className={`${styles.container_leaderboard} `}>255.000 CHAOS TOKEN GUARANTEED</h1>
	  </div>
      <table className={styles.container_playersTable}>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Player</th>
            <th>Upgrade-Points</th>
          </tr>
        </thead>
        <tbody>
          {currentPlayerIndex >= 10 && (
            <tr className={styles.container_playersTable_currentTr}>
              <td>{currentPlayerIndex + 1}</td>
              <td>{currentPlayer?.player}</td>
              <td>{currentPlayer?.leaderboardpts}</td>
            </tr>
          )}
          {leaders ? (
            leaders?.map((player, index) => (
              <React.Fragment key={player.player}>
                <tr>
                  <td>{index + 1}</td>
                  <td>{player.player}</td>
                  <td>{Number(parseFloat(player.leaderboardpts).toFixed(4))}</td>
				</tr>				
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td>
                <Loader size={50} />
              </td>
              <td>
                <Loader size={50} />
              </td>
              <td>
                <Loader size={50} />
              </td>
              <td>
                <Loader size={50} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </motion.div>
  );
};

export default Leaderboard;
