import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from "./styles.module.scss";
import { getMyNfts } from "../../GlobalState/NftsSlice/nftsSlice";
import image775642 from './775642.webp';
import image657357 from './657357.webp';
import image780257 from './780257.webp';
import image779970 from './779970.webp';
import image775109 from './775109.webp';
import image772162 from './772162.webp';
import image780256 from './780256.webp';
import image781627 from './781627.webp';
import image765612 from './765612.webp';
import image749229 from './749229.webp';
import image735593 from './735593.webp';
import image784439 from './784439.png';
import image526999 from './526999.gif';
import image753148 from './753148.webp';
import image532560 from './532560.png';
import common1 from './common1.png';
import common2 from './common2.png';
import rare1 from './rare1.png';
import rare2 from './rare2.png';
import epic1 from './epic1.png';
import epic2 from './epic2.png';
import legendary1 from './legend1.png';
import legendary2 from './legend2.png';
import mythic1 from './mythic1.png';
import mythic2 from './mythic2.png';
import divine1 from './divine1.png';
import divine2 from './divine2.png';
import cosmic from './cosmic.png';
import image786319 from './786319.webp';
import image784973 from './784973.webp';
import image784980 from './784980.webp';
import image784983 from './784983.webp';
import image784982 from './784982.webp';
import image785068 from './785068.webp';
import image785069 from './785069.webp';
import image785070 from './785070.webp';
import image785071 from './785071.webp';
import image786096 from './786096.webp';
import image786097 from './786097.webp';
import image786100 from './786100.webp';
import image786101 from './786101.webp';
import image786229 from './786229.webp';
import image786230 from './786230.webp';
import image786231 from './786231.webp';
import image786232 from './786232.webp';
import image786316 from './786316.webp';
import image786317 from './786317.webp';
import image786318 from './786318.webp';
import image785678 from './785678.jpg';

import { UserService } from "../../UserService";

const templateWeights = {
  '775642': 876,
  '657357': 251,
  '780257': 164,
  '779970': 116,
  '775109': 247,
  '772162': 323,
  '780256': 192,
  '781627': 278,
  '765612': 219,
  '749229': 135,
  '735593': 176,
  '526999': 275,
  '753148': 44,
  '532560': 49, 
  '784082': 55,
  '784199': 64,
  '784200': 105,
  '784202': 115,
  '784204': 175,
  '784205': 185,
  '784211': 290,
  '784213': 274,
  '784214': 475,
  '784215': 495,
  '784216': 660,
  '784232': 710,
  '784764': 2108,
  '786319': 174,
  '784973': 174,
  '784980': 174,
  '784983': 174,
  '784982': 174,
  '785068': 174,
  '785069': 174,
  '785070': 174,
  '785071': 174,
  '786096': 174,
  '786097': 174,
  '786100': 174,
  '786101': 174,
  '786229': 174,
  '786230': 174,
  '786231': 174,
  '786232': 174,
  '786316': 174,
  '786317': 174,
  '786318': 174, 
  '785678': 85,
};

const images = {
  '775642': image775642,
  '657357': image657357,
  '780257': image780257,
  '779970': image779970,
  '775109': image775109,
  '772162': image772162,
  '780256': image780256,
  '781627': image781627,
  '765612': image765612,
  '749229': image749229,
  '735593': image735593,
  '526999': image526999,
  '753148': image753148,  
  '532560': image532560,
  '784082': common1,
  '784199': common2,
  '784200': rare1,
  '784202': rare2,
  '784204': epic1,
  '784205': epic2,
  '784211': legendary1,
  '784213': legendary2,
  '784214': mythic1,
  '784215': mythic2,
  '784216': divine1,
  '784232': divine2, 
  '784764': cosmic, 
  '786319': image786319,
  '784973': image784973,
  '784980': image784980,
  '784983': image784983,
  '784982': image784982,
  '785068': image785068,
  '785069': image785069,
  '785070': image785070,
  '785071': image785071,
  '786096': image786096,
  '786097': image786097,
  '786100': image786100,
  '786101': image786101,
  '786229': image786229,
  '786230': image786230,
  '786231': image786231,
  '786232': image786232,
  '786316': image786316,
  '786317': image786317,
  '786318': image786318, 
  '785678': image785678,  
};

const calculateBonus = (templateId) => {
  const weight = templateWeights[templateId] || 0;
  const bonus = (2.5 / 876) * weight; 
  console.log(`Calcolo bonus per templateId ${templateId}: ${bonus}`);
  return bonus;
};

const MyBoosters = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyNfts());
  }, [dispatch]);

  const { myNfts } = useSelector((state) => state.nfts);
  console.log('NFT totali posseduti:', myNfts.length);

  // Trova il NFT del laboratorio
  const laboratoryNft = myNfts.find(nft => nft.template.template_id === '784439');

  // Estrai l'attributo "Edition" come numero intero
  const labEdition = laboratoryNft ? parseInt(laboratoryNft.mutable_data.Level, 10) : 0;

  const boosterNfts = myNfts.filter(nft => Object.keys(templateWeights).includes(nft.template.template_id));
  console.log('NFT filtrati per Token Boosters:', boosterNfts.length);

  const totalBonusByTemplateId = boosterNfts.reduce((acc, nft) => {
    if (!acc[nft.template.template_id]) {
      const bonus = calculateBonus(nft.template.template_id);
      acc[nft.template.template_id] = bonus;
    }
    return acc;
  }, {});
  
  let totalBonus = Object.values(totalBonusByTemplateId).reduce((acc, bonus) => acc + bonus, 0);


  if (labEdition > 0) {
    totalBonus += (totalBonus * 0.05) * labEdition
  }
    
  return (
    <div className={styles.container}>
      {!laboratoryNft ? (
        <h2>You don't have the laboratory.</h2>
      ) : (
        <>
          <h2>My Boosters - Laboratory Activated</h2>
          <br /><br />
          <h3>Your Laboratory is level: {labEdition}</h3>
		  
          <div>
            <img src={image784439} style={{ width: '30%', height: 'auto' }} alt="Laboratory" />
          </div>
          <h3>Your Active Boosters</h3>
          <div className={styles.boosterGrid}>
            {boosterNfts.map((nft) => (
              <div key={nft.template.template_id} className={styles.boosterItem}>
                <p>{nft.name}</p>
                <img src={images[nft.template.template_id]} className={styles.boosterImage} alt={nft.name} />
                <p>Bonus %: {calculateBonus(nft.template.template_id).toFixed(4)}</p>
              </div>
            )).filter((v, i, a) => a.findIndex(t => (t.key === v.key)) === i)}
          </div>
          <br />
          <h3>Total Token Booster %: {totalBonus.toFixed(4)}</h3>
        </>
      )}
    </div>
  );
};

export default MyBoosters;

