import React from "react";
import { motion } from "framer-motion";
import styles from "./styles.module.scss";

const GameRules = () => {
  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className={styles.container_gameInstructions}>
        <h2>
          🚀 Welcome to ChaosX-18 - Instructions to Become True Cosmic
          Explorers! 🚀
        </h2>
        <h2>Game Instructions:</h2>
        <p>
          If you don't have a WAX blockchain account yet:
          <br />
          Create a WAX account: You can do this by downloading the Anchor app (Greymass) on your smartphone. Click on "Add Account," then "Create account," and select "WAX Account" (please note that a small fee may be required).
          <br />
          Connect to the game: In the in-game menu, select "Connect Wallet," and then choose the login method you used to create your account (Anchor or Wax Cloud Wallet).
        </p>
        <p>
          If you already have a WAX account:
          <br />
          Connect to the game: In the in-game menu, select "Connect Wallet," and then choose the login method you normally use (Anchor or Wax Cloud Wallet).
        </p>
        <h3>Required Game Equipment:</h3>
        <p>
          <b>Teleport NFT:</b> This NFT is essential to play. You can purchase it from the Official Store (https://neftyblocks.com/collection/cryptochaos1/drops/189155) or from a secondary marketplace of your choice (AtomicHub, NFTHive). Burn the NFT to participate in the current season (each season starts on the 15th of every month and ends on the 14th at 10:00 PM UTC of the following month).
          <br />
          <b>Building Slot:</b> This NFT allows you to have two mining slots in your Extraction Pit. Once staked (in the My NFT page), it will enable you to stake one Aurum mine and one Celium mine.
          <br />
          <b>Aurum and Celium Mines:</b> These are necessary to produce the respective resources (Aurum and Celium) and, once a Building Slot is staked, they can be selected (simultaneously) and activated for that Building Slot.
          <br />
          <b>Level Up NFT:</b> This NFT is a consumable material that is burned after each use and allows you to increase the level of the mines by melting a portion of Aurum and Celium resources.
        </p>
        <h3>My NFTs Page:</h3>
        <p>
          In this page, you can view all the NFTs you own in your wallet and which ones you have already staked in-game:
          <br />
          Start with burning the Teleport NFT if you haven't done so already in the current season.
          <br />
          Select one or more Building Slots and click on the "stake" button to put them into play.
          <br />
          Simultaneously select one Aurum mine and one Celium mine and add them to the staked Building Slot to activate production.
        </p>
        <h3>MyProfile Page:</h3>
        <p>
          Here you will find an overview of your mining complex, with detailed information about your mines. By clicking on the details of a single mine, you will be shown data regarding production, cost, and development times for the building.
          <br />
          Click "Claim now" under each mine to collect the produced resource.
          <br />
          Click "Upgrade now" to increase the level if you have enough resources and a Level Up NFT.
        </p>
        <h3>Leaderboard:</h3>
        <p>
          The leaderboard displays the progress, with a delay of approximately 30 seconds (technical times for transferring information from the blockchain), of all active and inactive players. Active players are those who have at least 1 seasonal point on the leaderboard, while inactive players are those who have not participated in the season but have played in the past.
          <br />
          Accounts that have been inactive for 5 consecutive seasons will be irreversibly deleted, regardless of their in-game equipment.
          <br />
          At the end of each season, the prize pool will be automatically sent to players who actively participated in the season. For example, player A will receive 10 times more tokens than player B if A has accumulated 100 points and B has accumulated 10.
        </p>
        <p>
          <b>
            🕐 Ensure Daily Access: Attention, explorers! To maximize your
            wealth, remember to log in at least once every 24 hours. If more
            than 24 hours pass since your last access, the production of your
            mines will pause until your next login.
          </b>
        </p>
        <p>
          <b>
            🚀 Now, Cosmic Explorers, the time has come to immerse yourselves
            into the abyss of ChaosX-18! 🚀
          </b>
        </p>
        <p>
          <b>
            🌌 Discover, Conquer, and Become Legends of the Cosmic Mining! 🌌
          </b>
        </p>
      </div>
      <div className={styles.container_gameRules}>
        <h2>Game Rules and Terms of Use - ChaosX-18</h2>
        <ol>
          <li>
            <b>Game Access:</b>
            <ul>
              <li>
                You need a WAX account and a Teleport NFT, which is burned and grants access to the ongoing season. Each season starts on the 15th at 00:00 AM UTC of every month and ends on the 14th of the following month at 10 PM UTC.
              </li>
              <li>Login is possible with Wax Cloud Wallet or Anchor Wallet.</li>
            </ul>
          </li>
          <li>
            <b>Minimum Age:</b>
            <ul>
              <li>
                No additional registration is required, but you must be of legal age.
              </li>
            </ul>
          </li>
          <li>
            <b>Ownership of NFTs:</b>
            <ul>
              <li>
                NFTs belong to users and can be traded, burned, and sold.
              </li>
              <li>
                With the exception of the Level Up NFT, which is a consumable material and entitles you to upgrade a mine by one level.
              </li>
            </ul>
          </li>
          <li>
            <b>Use of NFTs:</b>
            <ul>
              <li>
                Once NFTs are staked, they become unavailable for all other apps, dapps, and wallets.
              </li>
              <li>
                Each Mine can be withdrawn from the game by "unstaking" the associated Building Slot. This is done on the My NFT page by clicking on the Building Slot to remove it from the game. By doing this, the Aurum mine and Celium mine linked to it will be removed. The unstaking operation will return the Building Slot, Aurum mine, and Celium mine to the user 24 hours after the request.
              </li>
            </ul>
          </li>
          <li>
            <b>Fair Play:</b>
            <ul>
              <li>
                Only one account per user is allowed.
              </li>
              <li>
                Hacking and the use of bots are prohibited.
              </li>
            </ul>
          </li>
          <li>
            <b>Rewards and Prizes:</b>
            <ul>
              <li>
                Seasonal rewards per user are based on the user's points divided by the total points made by all users.
              </li>
              <li>
                The prize pool varies each season with the increase or decrease in the number of users.
              </li>
              <li>
                The total prize pool increases with more players, items sold in Wax, the number of mines in-game, and contributions from sponsorship partners if present.
              </li>
            </ul>
          </li>
          <li>
            <b>Support and Official Communication:</b>
            <ul>
              <li>
                Use official Discord and Telegram channels for assistance, updates, complaints, or suggestions.
              </li>
              <li>
                All forms of partnership, collaboration, sponsorship should take place through official channels.
              </li>
            </ul>
          </li>
          <li>
            <b>Maintenance and Updates:</b>
            <ul>
              <li>
                Systematic updates, improvements, and new features are planned with the sole purpose of enhancing the user experience and enriching the game with exciting features.
              </li>
              <li>
                Periodic community voting on ideas takes place on official channels.
              </li>
            </ul>
          </li>
		  <li>
            <b>Legal Compliance</b>
            <ul>
              <li>
                Users must comply with local laws and regulations regarding
                games and cryptocurrencies.
              </li>
            </ul>
          </li>
          <li>
            <b>Limitation of Liability:</b>
            <ul>
              <li>
                The game is not responsible for disconnections or technical issues resulting from poor signal reception from users' devices. It is recommended to use Wi-Fi or stable mobile connections.
              </li>
            </ul>
          </li>
        </ol>
        <p>
          Please respect all rules and play in a fair and respectful way. Remember, the most important thing is to have fun!
        </p>
      </div>
    </motion.div>
  );
};

export default GameRules;
