import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

import styles from "./styles.module.scss";

import { User, UserService } from "../../UserService";

import Loader from "../../components/Loader/Loader";
import { getMyNfts } from "../../GlobalState/NftsSlice/nftsSlice";

import RequiredNftModal from "../../components/Modal/RequiredNftModal/RequiredNftModal";
import WithdrawAmountModal from "../../components/Modal/WithdrawAmountModal/WithdrawAmountModal";

import NoDataMessage from "../../components/NoDataMessage/NoDataMessage";
import Button from "../../components/Button/Button";
import cave from "./cave.jpg";
//import { mineNft } from "../../components/Mining/Mining"; //extra

const Mining = React.lazy(() => import("../../components/Mining/Mining"));



const PlayerProfile = () => {
  const gifPath = "../../components/Button/bottone.gif";	
  const dispatch = useDispatch();
  const [allMines, setAllMines] = useState([]);
  const [player, setPlayer] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(null);
  const { name, waxConnected, anchorConnected } = useSelector(
    (state) => state.user
  );
  
  //const mineNft = (mine) => {
    //if (anchorConnected) {
      //mineWithAnchor(mine);
    //} else if (waxConnected) {
      //mineWithWaxCloud(mine);
    //}
  //};
  
  const [modalIsOpen, setIsOpen] = useState(false);
  const pollingInterval = 20000;
  //console.log("Name:", name); // Controlla il valore di "name" per assicurarti che sia corretto.

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  // withdraw all
  const withdrawAllWithAnchor = (player) => {
    if (buttonLoader) return;

    setButtonLoader(player.player);
    User.anchorSession
      ?.transact(
        {
          actions: [
            {
              account: "xcryptochaos",
              name: "withdraw",
              authorization: [
                {
                  actor: User.anchorSession?.auth?.actor.toString(),
                  permission: "active",
                },
              ],
              data: {
                player: User.anchorSession?.auth?.actor.toString(),
                amount: player.prev_leaderboardpts,
              },
            },
          ],
        },
        {
          blocksBehind: 3,
          expireSeconds: 30,
        }
      )
      .then((_) => {
        toast.success("Withdraw all completed");
        setButtonLoader(null);
      })
      .catch((error) => {
        toast.error("Value is more than your balance or doesn't exist");
        setButtonLoader(null);
      });
  };

  const withdrawAllWithWaxCloud = (player) => {
    if (buttonLoader) return;

    setButtonLoader(player.player);
    User.wax.api
      .transact(
        {
          actions: [
            {
              account: "xcryptochaos",
              name: "withdraw",
              authorization: [
                {
                  actor: User.wax?.userAccount,
                  permission: "active",
                },
              ],
              data: {
                player: User.wax?.userAccount,
                amount: player.prev_leaderboardpts,
              },
            },
          ],
        },
        {
          blocksBehind: 3,
          expireSeconds: 30,
        }
      )
      .then((_) => {
        toast.success("Withdraw all completed");
        setButtonLoader(null);
      })
      .catch((error) => {
        toast.error("Value is more than your balance or doesn't exist");
        setButtonLoader(null);
      });
  };

  const withdrawAll = () => {
    if (waxConnected) {
      withdrawAllWithWaxCloud(player);
    } else if (anchorConnected) {
      withdrawAllWithAnchor(player);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [mines, players] = await Promise.all([
          UserService.getMines(name),
          UserService.getPlayers(),
        ]);
        if (mines) {
          setAllMines(mines);
        }

        if (players && players.rows) {
          const data = players.rows.find((player) => player.player === name);
          setPlayer(data);
        }
      } catch (error) {
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, pollingInterval);
    return () => clearInterval(intervalId);
  }, [name]);

  useEffect(() => {
    dispatch(getMyNfts());
  }, [dispatch]);

  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className={styles.container_mainInfo}>
        <div className={styles.container_mainInfo_mines}>
		  <Button size="fit" color="blue" disabled={!player}>
           MATERIALS
          </Button>
          <div>
            <p>AuruM: {!player ? 0 : player.aurum.slice(0, -13)}</p>
            <p>CeliuM: {!player ? 0 : player.celium.slice(0, -13)}</p>
          </div>
        </div>
        <div className={styles.container_mainInfo_earnings}>
		  <Button size="fit" color="blue" disabled={!player}>
           UPGRADE POINTS
          </Button>
          <div>
            {/*<p>Last season points: {!player ? 0 : player.prev_leaderboardpts.slice(0, -10)}</p>*/}
            <p>{!player ? 0 : player.leaderboardpts.slice(0, -13)}</p>
          </div>
        </div>
      </div>
      <div className={styles.container_btnWrapper}>
        {/*<Button onClick={openModal} size="fit" color="blue" disabled={!player}>
          //Withdraw
        //</Button>*/}
        {/*<Button
          //onClick={withdrawAll}
          //size="fit"
          //color="blue"
          //disabled={!player}
        //>
          //Withdraw all
        //</Button>*/}
      </div>
          <div>
            <img src={cave} style={{ width: '100%', height: 'auto' }} alt="Mining cave" align="center"/>
	      </div>
	  <h2 className={styles.container_mining}>Season 9 - 255000 Guaranteed Chaos Token Reward + NFTs</h2>
      <div className={styles.container_miningWrapper}>
        {allMines[0] ? (
          allMines.map((mine) => {
            return (
              <div key={mine.id}>
                <React.Suspense fallback={<Loader size={250} />}>
                  <Mining mine={mine ? mine : {}} />
                </React.Suspense>
              </div>
            );
          })
        ) : (
          <NoDataMessage message="Please wait, i´m searching for your miners in the blockchain" />
        )}
      </div>
      {player?.season_asset_id === 0 && <RequiredNftModal />}
      {modalIsOpen && (
        <WithdrawAmountModal player={player} onClose={closeModal} />
      )}
    </motion.div>
  );
};

export default PlayerProfile;
