import React, { useState } from "react";
import teleport from "./teleport.jpg";
import aurum from "./aurum.jpg";
import celium from "./celium.jpg";
import slot from "./slot.jpg";
import token from "./token.jpg";
import styles from "./styles.module.scss";
import Loader from "../../components/Loader/Loader";
import Button from "../../components/Button/Button";
import market from "./market.jpg";
import { motion } from "framer-motion";
import vip from './vip.webp';
import image657357 from './657357.webp';
import image780257 from './780257.webp';
import image779970 from './779970.webp';
import image775109 from './775109.webp';
import image772162 from './772162.webp';
import image780256 from './780256.webp';
import image781627 from './781627.webp';
import image765612 from './765612.webp';
import image749229 from './749229.webp';
import image735593 from './735593.webp';
import image526999 from './526999.jpg';
import lab from './lab.png';
import image753148 from './753148.webp';
import image532560 from './532560.png';
import image784316 from './784316.png';
import common1 from './common1.png';
import common2 from './common2.png';
import rare1 from './rare1.png';
import rare2 from './rare2.png';
import epic1 from './epic1.png';
import epic2 from './epic2.png';
import legendary1 from './legend1.png';
import legendary2 from './legend2.png';
import mythic1 from './mythic1.png';
import mythic2 from './mythic2.png';
import divine1 from './divine1.png';
import divine2 from './divine2.png';
import cosmic from './cosmic.png';
import image786319 from './786319.webp';
import image784973 from './784973.webp';
import image784980 from './784980.webp';
import image784983 from './784983.webp';
import image784982 from './784982.webp';
import image785068 from './785068.webp';
import image785069 from './785069.webp';
import image785070 from './785070.webp';
import image785071 from './785071.webp';
import image786096 from './786096.webp';
import image786097 from './786097.webp';
import image786100 from './786100.webp';
import image786101 from './786101.webp';
import image786229 from './786229.webp';
import image786230 from './786230.webp';
import image786231 from './786231.webp';
import image786232 from './786232.webp';
import image786316 from './786316.webp';
import image786317 from './786317.webp';
import image786318 from './786318.webp';
import image785678 from './785678.jpg';
const Marketplace = () => {

  const images = [
    teleport,
    aurum,
    celium,
    slot,
    token,
    lab,
    image784316,
    vip,
    common1,
    common2,
    rare1,
    rare2,
    epic1,
    epic2,
    legendary1,
    legendary2,
    mythic1,
    mythic2,
    divine1,
    divine2,
    cosmic,
    image657357,	//orlando
    image735593,
    image749229,
    image765612,
    image772162,
    image775109,
    image779970,
    image780256,
    image780257,
    image781627,
    image526999,	//smellykoalay
    image753148,	//santawaxnfts
    image532560,	//funmangalaxy
    image784973,    // youtouchdust
    image784980,
    image784983,
    image784982,
    image785068,
    image785069,
    image785070,
    image785071,
    image786096,
    image786097,
    image786100,
    image786101,
    image786229,
    image786230,
    image786231,
    image786232,
    image786316,
    image786317,
    image786318,
    image786319,
    image785678,	//amigos2space
  ];

  const dropNumbersNefty = [189155, 195466, 195467, 195468, 195469, 214438, 214396, 210578];


  const dropNumbersNftHive = [5480, 5529, 5530, 5526, 5527];

  // Product details
  const productDetails = [
    {
      name: "Teleport",
      description: "This NFT is the core of the Chaos X-18 game. Once you own it, you'll need to burn it in your wallet to participate in the current Chaos Game season. Don't worry: players joining a season will not only receive a share of the Chaos Token Prize Pool but also a Teleport NFT Game Pass for the upcoming season. It's a one-time purchase, allowing you to play as many seasons as you want!",
      price: "25 WAX",
	  type: "Game Pass",
	  BoosterWeight: "N/A",
      effect: "It guarantees you the opportunity to join as many seasons as you want. Burn it on Atomic Hub or another platform to start your journey and earn a lot of crypto! As a token of gratitude, at the end of each played season, you will receive another Teleport Game Pass for the next season!",
      howToUse: "This NFT is the core of the Chaos X-18 game. Once you own it, you'll need to burn it in your wallet to participate in the current Chaos Game season.",
    },
    {
      name: "Aurum Miner",
      description: "The Aurum miner can be used to extract Aurum: this resource allows you, together with Celium and Level Up Token, to level up your mines and mine even more materials. For each upgrade you make to your mine, you will earn Season Points, which will then be converted into Chaos Tokens at the end of each season.",
      price: "10 WAX",
	  type: "Miner",
	  BoosterWeight: "N/A",
      effect: "For each upgrade of your miner, it will level up permanently, increasing its market value. Can you reach level 100 and own an Aurum Miner worth over 400 wax? Did you already know that you could have up to 20 of them? 😉",
      howToUse: "The Aurum miner can be used to extract Aurum: this resource allows you, together with Celium and Level Up Token, to level up your mines and mine even more materials.",
    },
    {
      name: "Celium Miner",
      description: "Enhance your mining experience with Celium Mine! Utilize Celium, along with Aurum and Level Up Tokens, to elevate your mines to new heights. Each upgrade adds to your Season Points, which will be converted into Chaos Tokens at the end of every season. The more you upgrade, the more materials you can mine. Ready to delve into the depths and reap the rewards?",
      price: "10 WAX",
	  type: "Miner",
	  BoosterWeight: "N/A",
      effect: "Unlock boundless seasons of adventure with Celium, the most coveted resource on Planet Chaos X-18! Upgrade your mining experience and elevate your mines to perpetual levels of prosperity. Burn Celium on Atomic Hub or any other platform to embark on your journey and accumulate crypto riches. As a gesture of appreciation, at the conclusion of each season, you'll be rewarded with another Teleport Game Pass for the upcoming season! How high can you soar with Celium? The possibilities are as endless as the seasons themselves! 🚀",
      howToUse: "Enhance your mining experience with Celium Mine! Utilize Celium, along with Aurum and Level Up Tokens, to elevate your mines to new heights.",
    },
    {
      name: "Building Slot",
      description: "The building slot is an artificial platform that provides the essential space for installing an Aurum Mine and a Celium Mine on your mining site. It is a prerequisite, and it must be staked first, creating the foundation for your mining operations. Secure your building slot to unlock the potential of Aurum and Celium mines, setting the stage for a prosperous mining venture on Chaos X-18! Plus, you can own up to 20 building slots, expanding your mining empire and maximizing your resource-generating capabilities.",
      price: "10 WAX",
	  type: "Land plot",
	  BoosterWeight: "N/A",
      effect: "Owning multiple Building Slots enhances your mining capabilities on Chaos X-18. Each slot accommodates one Aurum Miner and one Celium Miner, increasing your material yield and Season Points. With more miners, you generate higher Chaos Token earnings. Maximize your potential by securing up to 20 Building Slots, allowing for the deployment of 20 Aurum and 20 Celium Miners simultaneously.",
      howToUse: "The building slot is an artificial platform that provides the essential space for installing an Aurum Mine and a Celium Mine on your mining site.",
    },	
    {
      name: "Level Up Token NFT",
      description: "Level Up Token is a consumable material. When used for a miner upgrade, it undergoes burning, paving the way for a next-level miner. This upgraded miner yields a higher quantity of materials and awards additional Season Points, proportionate to the upgrade level. Consequently, you gain more Chaos Tokens from the seasonal Reward Pool. It's a lucrative deal!",
      price: "3 WAX",
	  type: "Upgrading material",
	  BoosterWeight: "N/A",
      effect: "For each Miner upgrade, a Level Up Token is automatically burned. The higher the level of the miner you're advancing, the greater the amount of Season Points you'll receive. Remember, Season Points convert into Chaos Tokens at the end of the season!",
      howToUse: "Level Up Token is a consumable material. When used for a miner upgrade, it undergoes burning, paving the way for a next-level miner.",
    },
    {
      name: "Laboratory - Boosters Manager",
      description: "The Engine of your mining site, it will simplify your mining operation by allowing you to assume the boosters",
      price: "10 WAX",
	  type: "Boosters Activator",
	  BoosterWeight: "N/A",
      effect: "it add a +5% on your total Booster percentage( example: if you have 10% bonus and a laboratory level 2, the Total bonus will be 10.5% ",
      howToUse: "Hold it in your wallet, no staking required",
    },	
    {
      name: "X-18 Lab Powertool",
      description: "The way to upgrade your laboratory is here! If you got one from the Official drop, you can upgrade your Laboratory NFT here : https://neftyblocks.com/collection/cryptochaos1/upgrades/10",
      price: "4.99 WAX",
	  type: "Laboratory upgrading ingredient",
	  BoosterWeight: "N/A",
      effect: "Upgrade the Laboratory to the next level with this ingredient and gain 5% Bonus Booster with each upgrade",
      howToUse: "Go to https://neftyblocks.com/collection/cryptochaos1/upgrades/11 and upgrade your Laboratory NFT with the Powertool NFT",
    },		
    {
      name: "Token Booster : Vip Membership ",
      description: "This NTT crowns the owner as a VIP in front of the entire WAX Blockchain audience, offering benefits beyond imagination",
      price: "95 WAX",
	  type: "Booster - More Tokens",
	  BoosterWeight: "876",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
    },
    {
      name: "Token Booster : X-18 Driller V1.a - Common rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "55",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V1.a - Common rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "64",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V2.a - Rare rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "105",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V2.b - Rare rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "115",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V3.a - Epic rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "175",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V3.b - Epic rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "185",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V4.a - Legendary rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "290",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V4.b - Legendary rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "274",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V5.a - Mythic rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "475",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V5.b - Mythic rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "495",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V6.a - Divine rarity",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "660",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Driller V6.b - Divine rarity ",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "710",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },
    {
      name: "Token Booster : X-18 Drilling Station V7.0 - Cosmic rarity  ",
      description: "Booster used in the ChaosX-18 game",
      price: "Found in pack drop",
	  type: "Booster - More Tokens",
	  BoosterWeight: "2108",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://neftyblocks.com/collection/cryptochaos1/drops/214608",
    },		
    {
      name: "Ace of Clubs",
      description: "A collaboration with Orlando Castro and his collection BrSketchArt ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "251",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
	  customLink: "https://wax.atomichub.io/market?primary_chain=wax-mainnet&template_id=657357&order=asc&sort=price",
    },
    {
      name: "Azalea",
      description: "A collaboration with Orlando Castro and his collection BrSketchArt ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "176",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://waxdao.io/drops/2105",
	},
    {
      name: "Distrustful ape",
      description: "A collaboration with Orlando Castro and his collection BrSketchArt ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "135",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://wax.atomichub.io/market?primary_chain=wax-mainnet&template_id=749229&order=asc&sort=price",    
	},
    {
      name: "Bella",
      description: "A collaboration with Orlando Castro and his collection BrSketchArt ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "219",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://wax.atomichub.io/market?primary_chain=wax-mainnet&template_id=765612&order=asc&sort=price",    
	},
    {
      name: "Lady in a red hat",
      description: "A collaboration with Orlando Castro and his collection BrSketchArt ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "323",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/brsketchart1/drops/210061",    
	},
    {
      name: "Emerald necklace",
      description: "A collaboration with Orlando Castro and his collection BrSketchArt ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "247",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/brsketchart1/drops/211936",
    },
    {
      name: "Purple Cat",
      description: "A collaboration with Orlando Castro and his collection BrSketchArt ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "116",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://wax.atomichub.io/market?primary_chain=wax-mainnet&template_id=779970&order=asc&sort=price",
    },
    {
      name: "Blue Dog",
      description: "A collaboration with Orlando Castro and his collection BrSketchArt ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "192",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/brsketchart1/drops/212721", 
    },
    {
      name: "Pierrot",
      description: "A collaboration with Orlando Castro and his collection BrSketchArt ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "164",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/brsketchart1/drops/213601",
    },
    {
      name: "Kimbundu",
      description: "A collaboration with Orlando Castro and his collection BrSketchArt ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "278",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://wax.atomichub.io/market?primary_chain=wax-mainnet&template_id=781627&order=asc&sort=price",
    },
    {
      name: "Kchat Community Pass",
      description: "Spreading love with this collaboration with Smelly Koala and his collection smellykoalas ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "275",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/smellykoalas/drops/121406",
    },	
    {
      name: "Snow Drill",
      description: "Slots machines fan? Yes, of course ! here the Chaos dominate! ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "44",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/santawaxnfts/drops/202741",
    },
    {
      name: "Dung",
      description: "Who don´t want to have a dung ? ",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "49",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/funmangalaxy/drops/131287",
    },	
    {
      name: "They will come",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214716",
    },
    {
      name: "Harbinger of misfortune",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214717",
    },
    {
      name: "Chaos laufer",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214718",
    },
    {
      name: "Chaos laufer",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214719",
    },
    {
      name: "Lord of disorder",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214810",
    },
    {
      name: "Roots of chaos",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214811",
    },
    {
      name: "Queen of chaos",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214812",
    },
    {
      name: "Nobody cares about me",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214813",
    },
    {
      name: "As delicate as a memory",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214895",
    },
    {
      name: "Friends of the creatures of the night",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214896",
    },
    {
      name: "Not fallen, only love",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214897",
    },
    {
      name: "Decay beyond chaos",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214898",
    },
    {
      name: "Snow w.",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214969",
    },
    {
      name: "Don´t let go",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214970",
    },
    {
      name: "Cyber crush",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214971",
    },
    {
      name: "Cyber passion",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/214972",
    },
    {
      name: "Born again",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/215048",
    },
    {
      name: "Honey",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/215049",
    },
    {
      name: "A--17",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/215051",
    },
    {
      name: "Meet the girl",
      description: "Youtouchdust x ChaosX-18",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "174",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://neftyblocks.com/collection/youtouchdust/drops/215053",
    },
    {
      name: "Out of control booster",
      description: "A wonderful animated booster from Amigos2Space",
      price: "Unknown",
	  type: "Booster - More Tokens",
	  BoosterWeight: "85",
      effect: "It reward its owner who play the ChaosX18 game with a Token Booster reward based on how many points you get in a Season",
      howToUse: "Hold it in your wallet, no staking required",
      customLink: "https://nfthive.io/drop/8554",
    },		
  ];

  const [expandedDetailsIndex, setExpandedDetailsIndex] = useState(null);

  const handleBuyNeftyClick = (dropNumber) => {
    window.location.href = `https://neftyblocks.com/collection/cryptochaos1/drops/${dropNumber}`;
  };

  const handleBuyNftHiveClick = (dropNumber) => {
    window.location.href = `https://nfthive.io/drop/nfthivedrops/${dropNumber}`;
  };

  const handleDetailsClick = (index) => {
    setExpandedDetailsIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <motion.div
        className={styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >  
      <div>
            <img src={market} style={{ width: '100%', height: 'auto' }} alt="Market" />
      </div>
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Price</th>
			  <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {images.map((imageUrl, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <img src={imageUrl} alt={`Image ${index + 1}`} />
                  </td>
                  <td>{productDetails[index].name}</td>
                  <td>{productDetails[index].price}</td>
				  <td>{productDetails[index].type}</td>
                  <td>
                    {index < 8 ? (
                      <>
                        <Button
                          onClick={() => handleDetailsClick(index)}
                          color="blue"
                          size="medium"
                        >
                          {expandedDetailsIndex === index ? "Hide Details" : "Show Details"}
                        </Button>
                        <Button
                          onClick={() => handleBuyNeftyClick(dropNumbersNefty[index])}
                          color="olive"
                          size="medium"
                        >
                          Buy on Nefty
                        </Button>
                        <Button
                          onClick={() => handleBuyNftHiveClick(dropNumbersNftHive[index])}
                          color="blue"
                          size="medium"
                        >
                          Buy on NftHive
                        </Button>
                      </>
                    ) : (
					  <>
                        <Button
                          onClick={() => handleDetailsClick(index)}
                          color="blue"
                          size="medium"
                        >
                          {expandedDetailsIndex === index ? "Hide Details" : "Show Details"}
                        </Button>
                      <Button
                        onClick={() => window.location.href = productDetails[index].customLink}
                        color="green"
                        size="medium"
                      >
                        Marketplace
                      </Button>
					  </>
                    )}
                  </td>
                </tr>
                {expandedDetailsIndex === index && (
                  <tr>
                    <td colSpan="4">
                      <div>
                        <p>Description: {productDetails[index].description}</p>
						<p>Effect: {productDetails[index].effect}</p>
                        <p>How to Use: {productDetails[index].howToUse}</p>
						<p>Booster Weight: {productDetails[index].BoosterWeight}</p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </motion.div>
    </>
  );
};

export default Marketplace;