import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import Loader from "./../../components/Loader/Loader";
import styles from "./styles.module.scss";

// Definisci i pesi per i template
const templateWeights = {
  '775642': 876,
  '657357': 251,
  '780257': 164,
  '779970': 116,
  '775109': 247,
  '772162': 323,
  '780256': 192,
  '781627': 278,
  '765612': 219,
  '749229': 135,
  '735593': 176,
  '526999': 275,
  '753148': 44,
  '532560': 49, 
  '784082': 55,
  '784199': 64,
  '784200': 105,
  '784202': 115,
  '784204': 175,
  '784205': 185,
  '784211': 290,
  '784213': 274,
  '784214': 475,
  '784215': 495,
  '784216': 660,
  '784232': 710,
  '784764': 2108,
  '786319': 174,
  '784973': 174,
  '784980': 174,
  '784983': 174,
  '784982': 174,
  '785068': 174,
  '785069': 174,
  '785070': 174,
  '785071': 174,
  '786096': 174,
  '786097': 174,
  '786100': 174,
  '786101': 174,
  '786229': 174,
  '786230': 174,
  '786231': 174,
  '786232': 174,
  '786316': 174
};

// Funzione per calcolare il bonus per un template ID
const calculateBonus = (templateId) => {
  const weight = templateWeights[templateId] || 0;
  const bonus = (2.5 / 876) * weight; 
  return bonus;
};

const Management = () => {
    const [leaders, setLeaders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const specificPlayers = [
        ".hejg.wam",
        "4qs32.c.wam",
        "agoscry4ever",
        "cia.q.c.wam",
        "colerus14512",
        "dh4ds.wam",
        "dpqqw.wam",
        "esabg.wam",
        "hethatshetar",
        "londoedan123",
        "lucacuna1234",
        "mpvee.wam",
        "pl.qw.wam",
        "poitthegiant",
        "qev4o.c.wam",
        "rafasya34raf",
        "sa3bu.wam",
        "sbwo2.c.wam",
        "sr5n4.c.wam",
        "war4luv.dust",
        "yg1kq.c.wam"
    ];

	const fetchTemplateOwners = async () => {
		const templateIds = ["784439", "775642", "657357", "780257", "779970", "775109", "772162", "780256", "781627", "765612", "749229", "735593", "526999", "753148", "532560", "784082", "784199", "784200", "784202", "784204", "784205", "784211", "784213", "784214" , "784215", "784216", "784232", "784764"];
		const ownerPromises = templateIds.map(async (templateId) => {
			const response = await fetch(`https://wax.api.atomicassets.io/atomicassets/v1/assets?template_id=${templateId}&limit=1000`);
			const data = await response.json();
			console.log(`Fetched data for template ID ${templateId}:`, data);
			return data.data.map(asset => ({ templateId, ownerId: asset.owner }));
		});
		return Promise.all(ownerPromises);
	};

    useEffect(() => {
        const getTemplateIds = async () => {
            setIsLoading(true);
            try {
                console.log("Fetching template owners...");
                const templateOwners = await fetchTemplateOwners();
                console.log("Template owners fetched:", templateOwners);
                const updatedLeaders = specificPlayers.map((player) => {
                    const uniqueTemplateIds = new Set(); // Utilizzare un Set per memorizzare i template ID unici
                    templateOwners
                        .flat()
                        .filter(owner => owner.ownerId === player)
                        .forEach(owner => uniqueTemplateIds.add(owner.templateId)); // Aggiungere il template ID unico al Set
                    console.log(`Unique template IDs owned by ${player}:`, Array.from(uniqueTemplateIds));
                    const totalBonus = Array.from(uniqueTemplateIds).reduce((acc, templateId) => acc + calculateBonus(templateId), 0);
                    return { player, templateIds: Array.from(uniqueTemplateIds), totalBonus };
                });
                console.log("Updated leaders:", updatedLeaders);
                setLeaders(updatedLeaders);
            } catch (error) {
                console.error("Failed to get template owners", error);
                toast.error("Failed to get template owners");
            } finally {
                setIsLoading(false);
            }
        };

        getTemplateIds();
    }, []);

    return (
        <motion.div className={styles.container} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
            <h1 className={styles.container_leaderboard}>Management Season 6: 15th JANUARY - 14th FEBRUARY 2024</h1>
            {isLoading ? (
                <Loader />
            ) : (
                <table className={styles.container_playersTable}>
                    <thead>
                        <tr>
                            <th>Pos.</th>
                            <th>Wallet</th>
                            <th>Boosters Manager (Lab NFT)</th>
                            <th>Boosters Owned</th>
                            <th>Total Token Bonus %</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaders.map((player, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{player.player}</td>
                                <td>
                                    {player.templateIds.includes("784439") ? "Yes" : "No Lab NFT owned"}
                                </td>
                                <td>
                                    {player.templateIds.length > 0 ? (
                                        player.templateIds.filter(templateId => templateId !== "784439").map((templateId, idx, arr) => (
                                            <span key={idx}>{templateId}{idx < arr.length - 1 ? ", " : ""}</span>
                                        )).length > 0 ? player.templateIds.filter(templateId => templateId !== "784439").map((templateId, idx, arr) => (
                                            <span key={idx}>{templateId}{idx < arr.length - 1 ? ", " : ""}</span>
                                        )) : "No booster owned"
                                    ) : (
                                        "No booster owned"
                                    )}
                                </td>
                                <td>
                                    {player.templateIds.includes("784439") ? player.totalBonus.toFixed(4) : "0"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </motion.div>
    );
};

export default Management;