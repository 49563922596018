import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NftsService from './nfts.service';

const initialState = {
  myNfts: [],
  myWorkingNfts: {},
  hasTemplate775642: false, //initialState
};

export const getMyNfts = createAsyncThunk(
  'nfts/getMyNfts',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState();
      console.log(`Current user name: ${state.user.name}`);
      const res = await NftsService.getNfts(state.user.name);
      console.log(`NFTs fetched for user: ${state.user.name}`, res.data);
      dispatch(setMyNfts(res.data));
    } catch (error) {
      console.error("Failed to fetch my NFTs:", error);
    }
  }
);

export const getMyWorkingNfts = createAsyncThunk(
  'nfts/getmyWorkingNfts',
  async (id, { dispatch }) => {
    try {
      const res = await NftsService.getWorkingNft(id)
      dispatch(setMyWorkingNfts(res.data.data))
    }
    catch (error) {
    }
  }
)

const nftsSlice = createSlice({
  name: 'nfts',
  initialState,
  reducers: {
    setMyNfts: (state, action) => {
      state.myNfts = action.payload;
      state.hasTemplate775642 = action.payload.some(
        (nft) => nft.template && nft.template.template_id === '775642' //informazione
      );	  
    },
    setMyWorkingNfts: (state, action) => {
      const { asset_id } = action.payload;
      state.myWorkingNfts[asset_id] = action.payload;
    },
    clearMyNfts: (state) => {
      state.myNfts = []
      state.myWorkingNfts = {};
      state.hasTemplate775642 = false; // Resetta l'informazione	  
    },
    setHasTemplate775642: (state, action) => {
      state.hasTemplate775642 = action.payload;
    },	
  }
});

export const {
  setMyNfts,
  setMyWorkingNfts,
  clearMyNfts,
  setHasTemplate775642, // Aggiungi la nuova azione qui  
} = nftsSlice.actions;

export default nftsSlice.reducer;
