import React from "react";
import { motion } from "framer-motion";
import styles from "./styles.module.scss";

const WhitepaperAndRoadmap = () => {
  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <h2>Chaos" Token Whitepaper on the Wax Blockchain</h2>
	  <b>Abstract:</b>
      <p>
        The Chaos token is a key financial element in the Wax blockchain ecosystem. This whitepaper provides a comprehensive overview of the project, with a particular emphasis on financial details, including initial investments and expenses incurred to date.      </p>
	  <b> Table of Contents:</b>
        <li>1.Introduction</li>
		<li>2.Project Objectives</li>
		<li>3.Use of the Chaos Token</li>
		<li>4.Technology and Architecture</li>
		<li>5.Key Features of the Chaos Token</li>
		<li>6.Token Distribution</li>
		<li>7.Roadmap</li>
		<li>8.Initial Investments</li>
		<li>9.Security and Compliance</li>
		<li>10.Tokenomics</li>
		<li>11.Governance and Decentralization</li>
		<li>12.Ecosystem Development</li>
		<li>13.Community Engagement</li>
		<li>14.Fund Utilization</li>
		<li>15.Risks and Mitigations</li>
		<li>16.Monitoring and Evaluation</li>
		<li>17.Partnerships and Collaborations</li>
		<li>18.Conclusion</li>
      <h3>1. Introduction</h3>
      <p>The "Chaos" token was created to play a central role in the Wax blockchain ecosystem, with significant applications in the "ChaosX-18" game and NFT markets. This document provides a detailed overview of the project, including key financial aspects.</p>
      <h3>2. Project Objectives</h3>
      <p><b>Promote Cryptocurrency Adoption:</b> The "Chaos" token aims to drive cryptocurrency adoption by providing a concrete use case in gaming and NFT markets.</p>
      <p><b>Transaction Efficiency:</b> The primary goal is to provide an extremely fast, cost-effective, and efficient means of payment within the Wax ecosystem.</p>
      <p><b>Sustainability and Development:</b> The "Chaos" project focuses on creating a sustainable ecosystem through active community participation and continuous development.</p>
      <h3>3. Use of the Chaos Token</h3>
      <p><b>ChaosX-18 Game:</b> The "Chaos" token is the primary method of payout method within the "ChaosX-18" game. Players can use Chaos to acquire items, unlock premium content, and participate in special events.</p>
      <p><b>NFTyBlock and NFThive NFTs Marketplaces:</b> Chaos is accepted as a payment method on platforms such as NFTyBlock and NFThive, allowing users to purchase and trade digital assets, including NFTs.</p>
      <p><b>TacoSwap:</b> The "Chaos" token features multiple liquidity pools on TacoSwap.io, enabling users to provide liquidity M.E.A.L. and earn rewards.</p>
	  <p><b>Dividend Payments:</b> Chaos is used as a payment method for dividend distributions to liquidity providers on TacoSwap SALSA pools.</p>
	  <h3>4. Technology and Architecture</h3>
	  <p>The "Chaos" token is based on the Wax blockchain and utilizes the Delegated Proof of Stake (DPoS) protocol to ensure extremely fast and scalable transactions. Smart contract technology allows for the implementation of custom rules, including automatic dividend payments.</p>
      <h3>5. Key Features of the Chaos Token</h3>
	  <p><b>Speed and Low Costs:</b> Chaos offers extremely fast and low-cost transactions, ideal for use on gaming platforms and NFT markets.</p>
	  <p><b>Interoperability:</b> Chaos can be exchanged with other valid cryptocurrencies on the Wax blockchain, promoting an active exchange ecosystem.</p>
	  <p><b>Participation in Dividends:</b> Chaos holders can actively participate in governance and earn rewards in TacoSwap liquidity pools.</p>
	<h3>6. Token Distribution</h3>
	<p>The "Chaos" token was distributed through an initial sale, with a portion allocated to developers, incentives, and operational expenses. The remainder is available for trading on supported exchanges.</p>
	<h3>7. Roadmap</h3>
      <p><b>Phase 1:</b> Launch of the "ChaosX-18" game and initial integrations. <b>COMPLETED</b></p>
      <p><b>Phase 2:</b> Expansion into NFT markets and gaming platforms. <b>COMPLETED</b></p>
      <p><b>Phase 3:</b> Incentives for players and liquidity providers.<b>COMPLETED</b></p>
	  <p><b>Phase 4:</b> Further development of the "Chaos X-18 Game & Chaos Token" ecosystem and integration with other games and applications. <b>Before Q2-2024</b></p>
	<h3>8. Initial Investments</h3>
	<p>The creator of the "Chaos" token has supported an initial investment of 8,600 dollars to kickstart the project, including expenses for game development, token creation, exchange market launches, and advertising and airdrop costs. Additionally, they will need to cover an additional $9,300 in costs until the development is completed. </p>
    <h3>9. Security and Compliance</h3>
	<p>Security and compliance are fundamental priorities for the "Chaos" project. Rigorous measures have been implemented to ensure transaction security and compliance with existing regulations.</p>
	<h3>10. Tokenomics</h3>
	<p><b>Total supply:</b> 100.000.000.000</p>
	<h3>11. Governance and Decentralization</h3>
	<p>The "Chaos" project features an extremely decentralized governance model, allowing Chaos token holders to actively participate in decision-making regarding ecosystem development.</p>
	<h3>12. Ecosystem Development</h3>
	<p>The "Chaos" project is committed to ongoing ecosystem development with new features, integrations, and collaborations to promote adoption and expansion.</p>
	<h3>13. Community Engagement</h3>
	<p>Active community engagement is essential for the success of the "Chaos" project. Community participation tools, feedback, and initiatives will be encouraged and supported.</p>
	<h3>14. Fund Utilization</h3>
	<p>The management of funds is a critical aspect of the success and transparency of the "Chaos" project. Funds collected during the initial sale and those derived from other sources will be allocated judiciously to support the development and growth of the "Chaos" ecosystem.  </p>
	<h3>15. Risks and Mitigations</h3>
	<p>We acknowledge that every financial project carries potential risks, and the "Chaos" project is no exception. It is crucial to identify these risks and implement mitigation strategies to address them. </p>
	<h3>16. Monitoring and Evaluation</h3>
	<p>The "Chaos" project commits to continuous monitoring of its progress and evaluating the effectiveness of its initiatives to ensure sustainable growth. </p>
	<h3>17. Partnerships and Collaborations</h3>
	<p>Engagement with strategic partnerships and collaborations with other projects and platforms is a fundamental part of the growth plan for the "Chaos" ecosystem. </p>
	<h3>18. Conclusion</h3>
	<p>The "Chaos" token has established itself as a reliable and versatile means of payment within the Wax ecosystem. The project is committed to ongoing development and aims to contribute significantly to cryptocurrency adoption in the gaming and NFT industries. </p>
    </motion.div>
  );
};

export default WhitepaperAndRoadmap;

