import axios from '../../axios/axios';

const getNfts = async (waxAccount) => {
  console.log(`Fetching NFTs for account: ${waxAccount}`);
  const url1 = `https://wax.api.atomicassets.io/atomicassets/v1/assets/?owner=${waxAccount}&collection_name=cryptochaos1,brsketchart1,santawaxnfts&limit=1000&sort=rdata`;
  const url2 = `https://wax.api.atomicassets.io/atomicassets/v1/assets?template_id=532560&owner=${waxAccount}&limit=1&sort=rdata`;
  const url3 = `https://wax.api.atomicassets.io/atomicassets/v1/assets?template_id=526999&owner=${waxAccount}&limit=1&sort=rdata`;
  const url4 = `https://wax.api.atomicassets.io/atomicassets/v1/assets/?owner=${waxAccount}&collection_name=youtouchdust&limit=500&sort=rdata`;
  const url5 = `https://wax.api.atomicassets.io/atomicassets/v1/assets?template_id=785678&owner=${waxAccount}&limit=1&sort=rdata`;

  try {
    const [response1, response2, response3, response4, response5] = await Promise.all([axios.get(url1), axios.get(url2), axios.get(url3), axios.get(url4), axios.get(url5)]);
    const data1 = response1.data?.data || [];
    const data2 = response2.data?.data || [];
	const data3 = response3.data?.data || [];
	const data4 = response4.data?.data || [];
	const data5 = response5.data?.data || [];
    const combinedData = [...data1, ...data2, ...data3, ...data4, ...data5];
    return { data: combinedData };
  } catch (error) {
    console.error("Error fetching NFTs:", error);
    throw error;
  }
};


function getWorkingNft(id) {
  return axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets/${id}`)
  // for testnet
  // https://test.wax.api.atomicassets.io/atomicassets/v1/assets/${id}
  // for mainnet
  // https://wax.api.atomicassets.io/atomicassets/v1/assets/${id}
}

function getVIP(waxAccount) {
  const id = "775642";
  return axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets/${id}`);
}

const NftsService = {
  getNfts,
  getWorkingNft,
  getVIP, 
};

export default NftsService;

